<script>
/**
 * Topbar component
 */
export default {
  data() {
    return {};
  },
  methods: {
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="50" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>
      </div>

      <div class="d-flex">

        <b-dropdown
          class="d-none d-md-block ms-2"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <img
              class="me-2"
              src="@/assets/images/flags/us_flag.jpg"
              alt="Header Language"
              height="16"
            />
            English
            <span class="mdi mdi-chevron-down"></span>
          </template>
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img
              src="@/assets/images/flags/germany_flag.jpg"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">German</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img
              src="@/assets/images/flags/italy_flag.jpg"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">Italian</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img
              src="@/assets/images/flags/french_flag.jpg"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">French</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img
              src="@/assets/images/flags/spain_flag.jpg"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">Spanish</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img
              src="@/assets/images/flags/russia_flag.jpg"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">Russian</span>
          </a>
        </b-dropdown>

        <b-dropdown
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/user-4.jpg"
              alt="Header Avatar"
            />
          </template>
          <a class="dropdown-item text-danger" href="/logout">
            <i
              class="bx bx-power-off font-size-17 align-middle me-1 text-danger"
            ></i>
            Logout
          </a>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>
