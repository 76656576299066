const tableData = [
  {
    id: 1,
    name: 'Coffee machine',
    address: 'Recruiting Manager str.',
    status: 'Offline',
    date_last_connection: '2023-12-15 10:24:30.19837',
    coffee: 29,
    milk: 100,
    water: 100,
    position: {lat: 50.25355, lng: 28.66543}
  },
  {
    id: 2,
    name: 'Coffee machine',
    address: 'Recruiting Manager str.',
    status: 'Online',
    date_last_connection: '2023-12-11 10:24:30.19837',
    coffee: 29,
    milk: 100,
    water: 100,
    position: {lat: 51.25355, lng: 28.66543}
  },
];

export { tableData };
