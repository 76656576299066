export  function getColorByStatus(status){
    switch (status){
        case "Online":
            return "text-success"
        case "Offline":
            return "text-danger"
        default:
            return "text-danger"
    }

}