 const menuItems = [
     {
         id: 1,
         label: 'Dashboard',
         icon: 'ti-home',
         link: '/'
     },
     {
         id: 2,
         label: 'Analytics',
         icon: 'ti-view-grid',
         link: '/analytics'
     },
 ]

 export {
     menuItems
 };